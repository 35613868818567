import React from 'react';
import Navbar from './components/Navbar';
import PartyQuestion from './rsvp/PartyQuestion';
import ResponseQuestion from './rsvp/ResponseQuestion';
import ConfirmationQuestion from './rsvp/ConfirmationQuestion';

class RSVP extends React.Component {
    state = {
        step: 0,
        partySize: 0,
        section: "section1",
        showValidity: false,
        firstName: [],
        lastName: [],
        rsvp: [],
        dietary: [],
        declineNote: []
    }

    componentDidMount() {
        this.props.handleUserData();
    }
    
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }

    nextStep = () => {
        const { step, showValidity } = this.state;
        const form = document.getElementById('RSVP-form');
        if(step > 1 && !form.checkValidity()) {
            this.setState({
                showValidity: true
            })
        } else if(step > 1 && form.checkValidity() && showValidity) {
            this.setState({
                step: step + 1,
                showValidity: false
            })
        } else {
            this.setState({
                step: step + 1
            })
        }
    }
    
    handleSection = event => {
        event.preventDefault();
        this.setState({
            section: event.target.id
        })
    }

    handleParty = event => {
        this.setState(prevState => {
            if(event.target.value < prevState.partySize) {
                const firstList = [...prevState.firstName].slice(0, event.target.value);
                const lastList = [...prevState.lastName].slice(0, event.target.value);
                const rsvpList = [...prevState.rsvp].slice(0, event.target.value);
                const dietaryList = [...prevState.dietary].slice(0, event.target.value);
                const declineList = [...prevState.declineNote].slice(0, event.target.value);
                return { section: "section1", firstName: firstList, lastName: lastList, rsvp: rsvpList, dietary: dietaryList, declineNote: declineList };
            }
        })
        this.setState({
            partySize: parseInt(event.target.value)
        })
    }

    handleResponse = (input, index) => event => {
        const field = document.getElementById(event.target.id);
        field.reportValidity();
        this.setState(prevState => {
            const list = [...prevState[input]];
            list[index-1] = event.target.value;
            if(event.target.value === 'not going') {
                if(this.state.declineNote[index-1] === undefined) {
                    const declineList = [...prevState.declineNote];
                    declineList[index-1] = "";
                    const dietaryList = [...prevState.dietary];
                    dietaryList[index-1] = "";
                    return { [input]: list, dietary: dietaryList, declineNote: declineList };
                } else {
                    const dietaryList = [...prevState.dietary];
                    dietaryList[index-1] = "";
                    return { [input]: list, dietary: dietaryList };
                }
            } else if(event.target.value === 'going') {
                if(this.state.dietary[index-1] === undefined) {
                    const dietaryList = [...prevState.dietary];
                    dietaryList[index-1] = "";
                    const declineList = [...prevState.declineNote];
                    declineList[index-1] = "";
                    return { [input]: list, declineNote: declineList, dietary: dietaryList };
                } else {
                    const declineList = [...prevState.declineNote];
                    declineList[index-1] = "";
                    return { [input]: list, declineNote: declineList };
                }
            } else {
                return { [input]: list };
            }
        })
    }

    createResponses = (partySize) => {
        const { section, firstName, lastName, rsvp, dietary, declineNote, showValidity } = this.state;
        const rsvpResponses = { firstName, lastName, rsvp, dietary, declineNote };
        const rsvpList = [];
        for(let i = 1; i <= partySize; i++) {
            rsvpList.push(<ResponseQuestion key={i} id={i} section={section} showValidity={showValidity} rsvpResponses={rsvpResponses} handleSection={this.handleSection} handleResponse={this.handleResponse}/>);
        }
        return(rsvpList);
    }

    editResponses = (partyList) => {
        const { section, showValidity } = this.state;
        const partySize = this.state.partySize > 0 ? this.state.partySize : partyList.rsvp.length;
        const firstName = [];
        const lastName = [];
        const rsvp = [];
        const dietary = [];
        const declineNote = [];
        for(let i = 0; i < partySize; i++) {
            firstName[i] = this.state.firstName[i] !== "" && this.state.firstName[i] !== undefined ? this.state.firstName[i] : partyList.firstName[i];
            lastName[i] = this.state.lastName[i] !== "" && this.state.lastName[i] !== undefined ? this.state.lastName[i] : partyList.lastName[i];
            rsvp[i] = this.state.rsvp[i] !== "" && this.state.rsvp[i] !== undefined ? this.state.rsvp[i] : partyList.rsvp[i];
            dietary[i] = this.state.dietary[i] !== "" && this.state.dietary[i] !== undefined ? this.state.dietary[i] : partyList.dietary[i];
            declineNote[i] = this.state.declineNote[i] !== "" && this.state.declineNote[i] !== undefined ? this.state.declineNote[i] : partyList.declineNote[i];
        }
        const rsvpResponses = { firstName, lastName, rsvp, dietary, declineNote };
        const rsvpList = [];
        for(let i = 1; i <= partySize; i++) {
            rsvpList.push(<ResponseQuestion key={i} id={i} section={section} showValidity={showValidity} rsvpResponses={rsvpResponses} handleSection={this.handleSection} handleResponse={this.handleResponse}/>);
        }
        return(rsvpList);
    }

    reconfirmation = (partyList) => {
        const partySize = this.state.partySize > 0 ? this.state.partySize : partyList.rsvp.length;
        const firstName = [];
        const lastName = [];
        const rsvp = [];
        const dietary = [];
        const declineNote = [];
        for(let i = 0; i < partySize; i++) {
            firstName[i] = this.state.firstName[i] !== "" && this.state.firstName[i] !== undefined ? this.state.firstName[i] : partyList.firstName[i];
            lastName[i] = this.state.lastName[i] !== "" && this.state.lastName[i] !== undefined ? this.state.lastName[i] : partyList.lastName[i];
            rsvp[i] = this.state.rsvp[i] !== "" && this.state.rsvp[i] !== undefined ? this.state.rsvp[i] : partyList.rsvp[i];
            dietary[i] = this.state.dietary[i] !== "" && this.state.dietary[i] !== undefined ? this.state.dietary[i] : partyList.dietary[i];
            declineNote[i] = this.state.declineNote[i] !== "" && this.state.declineNote[i] !== undefined ? this.state.declineNote[i] : partyList.declineNote[i];
        }
        const rsvpResponses = { partySize, firstName, lastName, rsvp, dietary, declineNote };
        return <ConfirmationQuestion rsvpResponses={rsvpResponses}/>
    }
    
    handleSubmit = () => {
        const { firstName, lastName, rsvp, dietary, declineNote } = this.state;
        let acceptCount = 0;
        let declineCount = 0;
        for(let i = 0; i < rsvp.length; i++) {
            if(rsvp[i] === "going") {
                acceptCount = acceptCount + 1;
            } else if(rsvp[i] === "not going") {
                declineCount = declineCount + 1;
            }
        }
        const accept = acceptCount > 0 ? acceptCount : "";
        const decline = declineCount > 0 ? declineCount : "";
        const rsvpResponses = { firstName, lastName, rsvp, dietary, declineNote, accept, decline };

        this.props.handleCreateData(rsvpResponses).then(() => {
            const { step } = this.state;
            this.setState({
                step: step + 1
            })
        })
    }

    handleResubmit = (partyList) => {
        const partySize = this.state.partySize > 0 ? this.state.partySize : partyList.rsvp.length;
        const firstName = [];
        const lastName = [];
        const rsvp = [];
        const dietary = [];
        const declineNote = [];
        let acceptCount = 0;
        let declineCount = 0;
        for(let i = 0; i < partySize; i++) {
            firstName[i] = this.state.firstName[i] !== "" && this.state.firstName[i] !== undefined ? this.state.firstName[i] : partyList.firstName[i];
            lastName[i] = this.state.lastName[i] !== "" && this.state.lastName[i] !== undefined ? this.state.lastName[i] : partyList.lastName[i];
            rsvp[i] = this.state.rsvp[i] !== "" && this.state.rsvp[i] !== undefined ? this.state.rsvp[i] : partyList.rsvp[i];
            dietary[i] = this.state.dietary[i] !== "" && this.state.dietary[i] !== undefined ? this.state.dietary[i] : partyList.dietary[i];
            declineNote[i] = this.state.declineNote[i] !== "" && this.state.declineNote[i] !== undefined ? this.state.declineNote[i] : partyList.declineNote[i];
            if(rsvp[i] === "going") {
                acceptCount = acceptCount + 1;
                declineNote[i] = "";
            } else if(rsvp[i] === "not going") {
                declineCount = declineCount + 1;
                dietary[i] = "";
            }
        }
        const accept = acceptCount > 0 ? acceptCount : "";
        const decline = declineCount > 0 ? declineCount : "";
        const rsvpResponses = { firstName, lastName, rsvp, dietary, declineNote, accept, decline };
        
        this.props.handleUpdateData(rsvpResponses).then(() => {
            const { step } = this.state;
            this.setState({
                step: step + 1
            })
        })
    }

    render() {
        const { step, firstName, lastName, rsvp, dietary, declineNote, showValidity } = this.state;
        const partySize = this.state.partySize > 0 ? this.state.partySize : 1;
        const rsvpResponses = { partySize, firstName, lastName, rsvp, dietary, declineNote };
        const { rsvpData } = this.props;
        const checkRSVP = rsvpData.userData.party !== "" && rsvpData.userData.party !== undefined;
        const checkParty = rsvpData.guestParty !== null;
        const checkPartySize = rsvpData.guestParty.accept + rsvpData.guestParty.decline;
        const accept = checkParty && rsvpData.guestParty.accept !== "" ? `${rsvpData.guestParty.accept} going` : "";
        const decline = checkParty !== null && rsvpData.guestParty.decline !== "" ? `${rsvpData.guestParty.decline} not going` : "";
        return (
            <div className="App">
                <Navbar/>
                <div className="App-details">
                    <h1 className="App-section-title">RSVP</h1>
                    {step === 0 && Object.keys(rsvpData.userData).length > 0 && 
                        <div>
                            {!checkRSVP && <p>Kindly respond by April 25, 2025.</p>}
                            <p>Hi, {rsvpData.userData.firstName}</p>
                            <p>RSVP Status: {checkRSVP ?  `${accept}${accept !== "" && decline !== "" ? "," : ""} ${decline}` : "(no response)"}</p>
                            <button className="RSVP-submit" onClick={this.nextStep}>{checkRSVP ? "Edit RSVP" : "RSVP Here"}</button>
                        </div>
                    }
                    {step > 0 && 
                        <form id="RSVP-form" className="RSVP-form">
                            {step === 1 && <PartyQuestion partySize={checkRSVP && this.state.partySize === 0 ? checkPartySize : partySize} handleParty={this.handleParty}/>}
                            {step === 2 && (checkRSVP ? this.editResponses(rsvpData.guestParty) : this.createResponses(partySize))}
                            {step === 3 && (checkRSVP ? this.reconfirmation(rsvpData.guestParty) : <ConfirmationQuestion rsvpResponses={rsvpResponses}/>)}
                            {showValidity && <p className="subtitle">Please fill out missing fields.</p>}
                            {step < 4 && <button type="button" className="RSVP-submit" onClick={this.prevStep} >Previous</button>}
                            {step < 3 && partySize > 0 && <button type="button" className="RSVP-submit" onClick={this.nextStep} >Next</button>}
                            {step === 3 && <button type="button" className="RSVP-submit" onClick={checkRSVP ? () => this.handleResubmit(rsvpData.guestParty) : this.handleSubmit} >Submit</button>}
                            {step === 4 && <div><p>Thank you, responses {checkRSVP ? 'updated' : 'submitted'}!</p></div>}
                        </form>
                    }
                </div>
            </div>
        )
    }
}

export default RSVP;